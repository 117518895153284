<template>
  <b-modal
    :id="modalId"
    size="md"
    :title="modalTitle"
    centered
    scrollable
    title-class="font-22"
    hide-footer
    @shown="setData"
  >
    <b-form
      class="purchase-form"
      @submit.prevent="handleSubmit(form.status_id)"
    >
      <b-form-group
        label="Employee"
        label-cols-md="2"
        label-align="right"
        label-for="employee"
      >
        <p class="m-0 p-2">{{ employee }}</p>
      </b-form-group>
      <b-form-group
        label="Date"
        label-cols-md="2"
        label-align="right"
        label-for="date"
      >
        <p class="m-0 p-2">{{ date }}</p>
      </b-form-group>
      <b-form-group
        label="Type"
        label-cols-md="2"
        label-align="right"
        label-for="type"
      >
        <p class="m-0 p-2">{{ type }}</p>
      </b-form-group>
      <b-form-group
              label="File"
              label-cols-md="2"
              label-align="right"
              label-for="file"
              v-if="file"
      >
        <p class="m-0 p-2">
          <a :href="getFullPathToFile(form.file_id)" target="_blank"><i class="fa fa-download" aria-hidden="true"></i>
            Download</a>
        </p>
      </b-form-group>
      <b-form-group
              label="Link"
              label-cols-md="2"
              label-align="right"
              label-for="file"
              v-if="link"
      >
        <p class="m-0 p-2"><a :href="'//' + link" target="_blank">{{ link }}</a></p>
      </b-form-group>
      <!--<b-form-group
        label="Details"
        label-cols-md="2"
        label-align="right"
        label-for="type"
      >
        <table class="table" v-if="details.length">
          <tr v-for="(item, index) in details" :key="index">
            <td class="font-weight-bold">{{ item.title }}</td>
            <td class="text-capitalize">{{ item.value }}</td>
          </tr>
        </table>
        <div v-else class="p-2">No details</div>
      </b-form-group>-->
      <div v-for="(item, index) in details" :key="index">
        <b-form-group
                :label="item.title == 'Date' ? 'Event date' : item.title"
                label-cols-md="2"
                label-align="right"
                label-for="type"
                v-if="item.title != 'Comment'"
        >
          <div class="p-2">{{ item.value }}</div>
        </b-form-group>
      </div>
      <b-form-group
          v-if="initialData && initialData.type_id !== 9"
          label-cols-md="2"
          label-align="right"
          label-for="amount"
      >
        <template v-slot:label>
          Amount<span class="text-danger">*</span>
        </template>
        <b-form-input
            id="amount"
            v-model="form.amount"
            min="0"
            @blur="handleBlurNumber($event, 'form.amount')"
            type="number"
            required
        />
      </b-form-group>
      <b-form-group
        label-cols-md="2"
        label-align="right"
        label-for="comment"
      >
        <template v-slot:label>
          Comment<span class="text-danger">*</span>
        </template>
        <b-form-textarea
          id="comment"
          v-model="form.comment"
          required
        />
      </b-form-group>
      <b-form-group
        label-cols-md="2"
        label-align="right"
        label-for="comment"
      >
        <template v-slot:label>
          Status<span class="text-danger">*</span>
        </template>
        <b-form-select
          id="status"
          v-model="form.status_id"
          :options="statuses"
          value-field="id"
          text-field="title"
        ></b-form-select>
      </b-form-group>
      <div class="d-flex mt-4 justify-content-between">
        <div>
          <b-button
            v-if="form.status_id !== 3"
            type="button"
            variant="danger"
            @click="handleSubmit(3)"
            class="mr-2"
          >
            Decline
          </b-button>
          <ModalButton
            v-if="form.status_id !== 1"
            @click.native="handleSubmit(1)"
            type="button"
            variant="warning"
            buttonText="Pending"
            class="mr-2"
          />
          <ModalButton v-if="form.status_id !== 2" @click.native="handleSubmit(2)" type="button" variant="success" buttonText="Accept"/>
        </div>
        <div>
          <b-button
            type="submit"
            variant="primary"
          >
            Save
          </b-button>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import {validateForm} from '@/utils/validation';
import {FORM_MODE} from '@/utils/constants';
import moment from 'moment';
import ModalButton from '@/components/modal-button';
import { convertObjectToFormData } from '@/utils/converters';

export default {
  name: 'purchaseModalForm',
  props: {
    'modalId': {
      type: String,
      required: true
    },
    'initialData': Object,
    formMode: String,
    modalTitle: String
  },
  components: {
    ModalButton,
  },
  data() {
    return {
      employee: null,
      date: null,
      type: null,
      file: null,
      link: null,
      form: {
        amount: null,
        comment: null,
        status_id: null,
        file_id: null
      },
      details: [],
      titles: {
        app_id: 'App',
        book_id: 'Book Category',
        code: 'Code',
        date: 'Date',
        description: 'Description',
        employee_uid: 'Employee',
        is_send_as_anonymous: 'Send Anonymously',
        number_of_visitors: 'Number of Visitors',
        platform_id: 'Platform',
        product_id: 'Product',
        spend_amount: 'Amount',
        version: 'Version',
        comment: 'Comment',
        file: 'File',
        link: 'Link',
      },
    }
  },
  computed: {
    buttonText() {
      return this.formMode === FORM_MODE.CREATE ? 'Add' : 'Save';
    },
    types() {
      return this.$store.getters['moderatorView/types'];
    },
    statuses() {
      return this.$store.getters['moderatorView/statuses'];
    },
    booksCategories() {
      return this.$store.state.library.categories;
    },
    apps() {
      return this.$store.getters['balanceDetails/apps'];
    },
    platforms() {
      return this.$store.getters['balanceDetails/platforms'];
    },
    products() {
      return this.$store.getters['balanceDetails/products'];
    },
    employeesList() {
      return this.$store.getters['employees/employeesList'];
    },
  },
  methods: {
    async handleSubmit(status) {
      const data = { ...this.form, status_id: status };
      if (validateForm(this.form)) {
        let isSuccessLoad = false;
        await this.$store.dispatch('global/changeLoadingValue', true);
        if (this.formMode === FORM_MODE.CREATE) {
          const res = await this.$store.dispatch('moderatorView/createCoinRequest', convertObjectToFormData(data));
          if (res.code === 200) {
            isSuccessLoad = true;
          }
        }
        if (this.formMode === FORM_MODE.EDIT) {
          try {
            const res = await this.$store.dispatch('moderatorView/updateCoinRequest', {
              id: this.initialData.id,
              data: convertObjectToFormData({
                ...data,
                _method: 'PUT'
              })
            });
            if (res.code === 200) {
              isSuccessLoad = true;
            }
          } catch (e) {
            console.error(e);
          }
        }
        await this.$store.dispatch('global/changeLoadingValue', false);
        if (isSuccessLoad) {
          this.form.status_id = status;
          await this.$store.dispatch('moderatorView/getCoinRequests');
          this.$bvModal.hide(this.modalId);
        }
      }
    },
    setData() {
      this.details = [];
      this.employee = this.initialData ? this.initialData.transaction_account.employee.name : null;
      this.date = this.initialData ? moment(this.initialData.created_at).format('DD.MM.YYYY') : null;
      this.type = this.initialData ? this.initialData.transaction_type?.title : null;
      this.file = this.initialData ? this.initialData.transaction_details?.file : null;
      this.link = this.initialData ? this.initialData.transaction_details?.link : null;
      this.form.amount = this.initialData ? this.initialData.amount : null;
      this.form.comment = this.initialData ? this.initialData.transaction_details?.details?.comment : null;
      this.form.status_id = this.initialData ? this.initialData.status_id : null;
      this.form.file_id = this.initialData?.transaction_details.id || null;
      /*if (this.initialData.transaction_details?.file) {
        this.initialData.transaction_details.details['file'] = this.initialData.transaction_details?.file;
      }
      if (this.initialData.transaction_details?.link) {
        this.initialData.transaction_details.details['link'] = this.initialData.transaction_details?.link;
      }*/
      [...Object.entries(this.initialData.transaction_details?.details)].forEach(e => {
        if (e[0] === 'book_id') {
          e[1] = this.booksCategories.find(item => item.id === e[1])?.title || e[1]
        }
        if (e[0] === 'app_id') {
          e[1] = this.apps?.find(item => item.id === e[1])?.title || e[1]
        }
        if (e[0] === 'platform_id') {
          e[1] = this.platforms?.find(item => item.id === e[1])?.title || e[1]
        }
        if (e[0] === 'product_id') {
          e[1] = this.products?.find(item => item.id === e[1])?.title || e[1]
        }
        if (e[0] === 'employee_uid') {
          e[1] = this.employeesList?.data?.find(item => item.uid === e[1])?.name || e[1]
        }
        if (e[1]) {
          this.details.push({
            id: e[0],
            value: e[1],
            title: this.titles[e[0]]
          })
        }
      })
    },
    getFullPathToFile(id) {
      return process.env.VUE_APP_API_PATH + '/bimi-coins/attachment/' + id
    }
  },
  mounted() {
    this.$store.dispatch('balanceDetails/getAppsRequest')
    this.$store.dispatch('balanceDetails/getPlatformsRequest')
    this.$store.dispatch('balanceDetails/getProductsRequest')
    this.$store.dispatch('library/getLibrarySettings')
  }
}
</script>

<style lang="scss">
.survey-form {
  .custom-control-label {
    vertical-align: middle;
  }
}
</style>

<style lang="scss" scoped>
  .form-group{
    margin-bottom: 0.5rem;
  }
</style>
