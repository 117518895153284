<template>
    <layout class="leaderboard" :loading="loading">
        <ModalForm
          :modalId="modalId"
          :formMode="formMode"
          :initialData="currentRow"
          :modalTitle="modalTitle"
        />
        <b-modal
          id="bimicoins-modal"
          size="md"
          :title="modalTitle"
          centered
          scrollable
          title-class="font-22"
          hide-footer
          @show="handleOpenModal"
      >
        <b-form
            class="purchase-form"
        >
          <form action="" @submit.prevent="addCoins">
            <div class="form-group required">
              <label for="">Choose employee</label>
              <multiselect
                v-if="employeeListForSelect"
                class="blog-modal-tags"
                :options="employeeListForSelect"
                v-model="bimiModal.employees"
                placeholder="Search by employee"
                deselectLabel="Remove"
                selectLabel="Press"
                label="name"
                track-by="uid"
                multiple
                :limit="2"
                :close-on-select="false"
              >
                <template
                  v-if="employeeListForSelect.length"
                  #beforeList
                >
                  <span
                    class="multiselect__option multiselect__option-all"
                    @click="handleToggleEmployees"
                  >
                    {{ bimiModal.employees.length !== employeeListForSelect.length ? 'Select' : 'Unselect'}}  all
                  </span>
                </template>
              </multiselect>
            </div>
            <div class="form-group required">
              <label for="">Number of coins</label>
              <input
                  type="number"
                  class="form-control"
                  v-model="bimiModal.amount"
                  min="0"
                  @blur="handleBlurNumber($event, 'bimiModal.amount')"
              >
            </div>
            <div class="form-group required">
              <label>Coins Type</label>
              <multiselect
                v-model="bimiModal.type"
                :options="addTypes"
                :multiple="false"
                :allow-empty="false"
                :show-labels="false"
                label="title"
                track-by="id"
              />
            </div>
            <div class="form-group required">
              <label for="">Comment</label>
              <textarea name="" id="" cols="30" rows="4" class="form-control" v-model="bimiModal.commentary"></textarea>
            </div>
            <div class="d-flex mt-4 justify-content-between">
              <div>
                <b-button
                  type="submit"
                  variant="success"
                  class="mr-2"
                  :disabled="!isAddCoinsFormValid"
                >
                  Save
                </b-button>
              </div>
            </div>
          </form>
        </b-form>
      </b-modal>
        <div class="row">
          <div class="col-md-3">
                <h2 class="mb-4">Bimi Coin moderator</h2>
            </div>
          <div class="col-md-5">
            <div class="row">
              <div class="col-md-4">
                <multiselect
                    class="blog-modal-tags"
                    :options="types"
                    v-model="type"
                    placeholder="Search by type"
                    deselectLabel="Remove"
                    selectLabel="Press"
                    label="title"
                    track-by="id"
                    :multiple="true"
                >
                </multiselect>
              </div>
              <div class="col-md-4">
                <multiselect
                    v-if="employeesList"
                    class="blog-modal-tags"
                    :options="employeeListForSelect"
                    v-model="employee"
                    placeholder="Search by employee"
                    deselectLabel="Remove"
                    selectLabel="Press"
                    label="name"
                    track-by="uid"
                    :multiple="true"
                >
                </multiselect>
              </div>
              <div class="col-md-4">
                <multiselect
                    class="blog-modal-tags"
                    :options="statuses"
                    v-model="status"
                    placeholder="Search by status"
                    deselectLabel="Remove"
                    selectLabel="Press"
                    label="title"
                    track-by="id"
                    :multiple="true"
                >
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex justify-content-end w-100" v-if="$hasAccess(['admin', 'hrm'])">
              <button
                  class="btn btn-primary mr-2"
                  @click="handleDownloadCsv"
              >
                <i class="bx bxs-download"></i>
                CSV
              </button>
              <a class="btn btn-primary mr-2" href="#" @click.prevent="showBimiCoinsModal()" style="height: 36px" target="_blank"><i class="fa fa-plus mr-2" aria-hidden="true"></i> Add BimiCoins</a>
              <a class="btn btn-primary" href="/bimi-coin/codes-management" style="height: 36px" target="_blank"><i class="fa fa-plus" aria-hidden="true"></i> Add code</a>
            </div>
          </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body p-0">
                        <table class="table mb-0" v-if="list.length > 0">
                            <thead>
                            <th style="width: 50px">#</th>
                            <th>Type</th>
                            <th>Employee</th>
                            <th>Date</th>
                            <th style="text-align: right">Amount</th>
                            <th style="text-align: center">Status</th>
                            <th style="width: 50px">Actions</th>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in list" :key="item.id">
                                <td>{{index + 1}}</td>
                                <td>{{item.transaction_type ? item.transaction_type.title : ''}}</td>
                                <td>{{item.transaction_account && item.transaction_account.employee ?
                                    item.transaction_account.employee.name : ''}}
                                </td>
                                <td>{{moment(item.created_at).format('DD.MM.YYYY')}}</td>
                                <td style="text-align: right"><span v-if="item.type_id == 9">-</span>{{item.amount || 0}}</td>
                                <td style="text-align: center">
                                    <!--<span :class="`badge bg-${statClass[item.status_id].class}`">{{item.transaction_status ? item.transaction_status.title : ''}}</span>-->
                                    <span :class="`badge bg-`+badge(item.status_id)">{{item.transaction_status ? item.transaction_status.title : ''}}</span>
                                </td>
                                <td>
                                    <div class="d-flex justify-content-between">
                                      <b-button
                                          v-if="item.type_id !== 10"
                                          style="margin-top: -0.5rem;"
                                          variant="outline-primary"
                                          class="shadow-none border-0 p-2 mt--2 edit-btn"
                                          @click="handleEdit(item)"
                                      >
                                        <span class="fa fa-pen"></span>
                                      </b-button>
                                      <b-button
                                          v-if="(item.status_id === 1 || item.type_id === 10)"
                                          style="margin-top: -0.5rem;"
                                          variant="outline-danger"
                                          class="shadow-none border-0 p-2 mt--2 edit-btn"
                                          @click="handleDelete(item.id)"
                                      >
                                        <span class="fa fa-trash"></span>
                                      </b-button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <EmptyPage v-else />
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import {FORM_MODE} from '@/utils/constants';
import {convertObjectToFormData} from '@/utils/converters';
import ModalForm from './moderator-view-modal';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import EmptyPage from '@/components/empty-page';

export default {
  components: {
    ModalForm,
    Multiselect,
    EmptyPage
  },
  data() {
    return {
      leaderboards: [],
      modalId: 'request-modal',
      modalTitle: 'Bimi Coin request',
      currentRow: null,
      formMode: '',
      moment,
      type: '',
      status: '',
      employee: '',
      bimiModal: {
        type: null,
        employees: [],
        commentary: null,
        amount: null
      }
    };
  },
  async mounted() {
    await this.$store.dispatch('employees/getAllEmployees')
    await this.$store.dispatch('profile/getProfile');
    await this.$store.dispatch('moderatorView/getTypesRequest');
    await this.$store.dispatch('moderatorView/getBimiCoinAddTypes');
    await this.$store.dispatch('moderatorView/getStatusesRequest')
    await this.$store.dispatch('moderatorView/getCoinRequests')
  },
  methods: {
    handleOpenModal() {
      this.bimiModal.amount = null;
      this.bimiModal.employees = [];
      this.bimiModal.commentary = '';
      this.bimiModal.type = this.addTypes.find(({ title }) => title === 'Other');
    },
    handleToggleEmployees() {
      if (this.bimiModal.employees.length !== this.employeeListForSelect?.length) {
        this.bimiModal.employees = [...this.employeeListForSelect];
      } else {
        this.bimiModal.employees = [];
      }
    },
    handleAdd() {
        this.currentRow = null;
        this.formMode = FORM_MODE.CREATE;
        this.$bvModal.show(this.modalId);
    },
    handleEdit(row) {
        this.modalTitle = 'Bimi Coin request'
        this.currentRow = row;
        this.formMode = FORM_MODE.EDIT;
        this.$bvModal.show(this.modalId);
    },
    async addCoins () {
      if (this.isAddCoinsFormValid) {
        await this.$store.dispatch('moderatorView/addBimiCoinsForUser', convertObjectToFormData({
          commentary: this.bimiModal.commentary,
          amount: this.bimiModal.amount,
          type_id: this.bimiModal.type.id,
          employee_uid: this.bimiModal.employees.map(({ uid }) => uid),
          _method: 'PATCH'
        })).then(async (status) => {
          if (status) {
            await this.$store.dispatch('moderatorView/getCoinRequests')
            this.$bvModal.hide('bimicoins-modal');
          }
        })
      }
    },
    handleDelete(id) {
    this.$bvModal.msgBoxConfirm('Delete this item?', {
      title: 'Please Confirm',
      okVariant: 'danger',
      okTitle: 'Yes',
      cancelTitle: 'No',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    })
        .then(async (value) => {
          if (value) {
            await this.$store.dispatch('balanceDetails/deleteRequest', {
              data: convertObjectToFormData({_method: 'DELETE'}),
              id
            });
            await this.$store.dispatch('moderatorView/getCoinRequests')
          }
        });
    },
    badge(id) {
        return id == 1 ? 'warning' : id == 2 ? 'success' : 'danger'
    },
    showBimiCoinsModal() {
      this.modalTitle = 'Add bimicoins'
      this.$bvModal.show('bimicoins-modal')
    },
    async handleDownloadCsv() {
      await this.$store.dispatch('moderatorView/downloadModeratorViewCSV')
    }
  },
  computed: {
    list() {
      let list = [...this.$store.getters['moderatorView/coinRequestList']];
      if (this.status?.length) {
        list = list.filter(item => this.status.some(status => status.id === item.status_id));
      }
      if (this.type?.length) {
        list = list.filter(item => this.type.some(type => type.id === item.type_id));
      }
      if (this.employee?.length) {
        list = list.filter(item => this.employee.some(employee => employee.uid === item.transaction_account?.employee_uid));
      }

      return list;
    },
    loading() {
        return this.$store.state.moderatorView.loading;
    },
    types() {
        return this.$store.getters['moderatorView/types'];
    },
    addTypes() {
      return this.$store.state.moderatorView.addTypes;
    },
    statuses() {
        return this.$store.getters['moderatorView/statuses'];
    },
    employeesList() {
        return this.$store.getters['employees/allEmployees'];
    },
    employeeListForSelect () {
      return this.$store.getters['employees/employeeListForSelect']
    },
    isAddCoinsFormValid() {
      return (
        this.bimiModal.type &&
        +this.bimiModal.amount &&
        this.bimiModal.commentary &&
        this.bimiModal.employees.length
      );
    }
  }
};
</script>

<style lang="scss" scoped>
    .balance {
        font-size: 16px;

        span.amount {
            font-size: 30px;
        }
    }

    .leaderboard table img {
        max-width: 200px;
    }

    table, thead, tbody {
        width: 100%;

        th, td {
            font-size: 14px;
        }
    }

    .badge {
        color: #fff;
        font-size: 14px;
    }
</style>
<style>
.multiselect__placeholder {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}
</style>
